#whowearegrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  width: 100%;
  max-width: fit-content;
  min-height: 916px;
  margin: 20px auto;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 404px 186px 186px 404px;
  grid-template-rows: 284px 284px 284px 284px;
  gap: 32px 32px;
  grid-template-areas:
    'oil business business business'
    'oil rail rail rail'
    'health corp corp constru'
    'event event event constru'
  ;
}

.oil {
  grid-area: oil;
}

.health {
  grid-area: health;
}

.corp {
  grid-area: corp;
}

.rail {
  grid-area: rail;
}

.event {
  grid-area: event;
}

.business {
  grid-area: business;
}

.constru {
  grid-area: constru;
}

.cardTitle {
  font-size: 28px;
  color: #ffffff;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  margin-bottom: 20px;
}

.cardDesc {
  font-size: 17px;
  color: #ffffff;
  opacity: 0;
  bottom: 0;
  max-width: 404px;
  max-height: 0;
  transition: all 0.5s ease-in-out;
}

.card-overflow {
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, #12121200 70%, #121212 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */
  border-radius: 6px;
}

.hover {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 32px 20px;
  z-index: 1;
}

.card-overflow:hover::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: #121212 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 0.8;
  animation: moveShadowin 0.5s ease-in;
  animation-fill-mode: forwards;
  z-index: 0;
}

.card-overflow:not(:hover)::before {
  animation: moveShadowout 0.5s ease-out;
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: #121212 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 0.8;
  animation-fill-mode: forwards;
}

/* .card-overflow:hover>.hover {
  opacity: 1;
  transform: translateY(-20%);
  transition: transform 0.5s ease, opacity 0.5s ease;

} */

.card-overflow:hover>.hover>.cardDesc {
  opacity: 1;
  max-height: 500px;


}




/* 
.card-overflow:hover>.hover>.cardTitle {
  padding: 32px 32px 10px;
} */



.card-overflow:hover {
  justify-content: flex-start;
}

.card-clients {
  display: flex;
  gap: 32px;
  justify-content: center;
  background-color: white;
  max-height: 70px;
  align-items: center;
  margin: auto 0px 20px 0px;
  opacity: 0;
  z-index: 1;
}

.card-clients>img {
  width: 100px;
  height: 100px;
}

.card-overflow:hover>.card-clients {
  animation: showClients 0.5s ease-in;
  animation-fill-mode: forwards;
}

.card-overflow:not(:hover)>.card-clients {
  animation: showClientsout 0.5s ease-out;
  animation-fill-mode: forwards;
}

@keyframes showClients {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes showClientsout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes moveTitlein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes moveDesin {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveShadowin {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

@keyframes moveDesout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes moveShadowout {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}









@media screen and (min-width:1360px) and (max-width:1439px) {
  .grid-container {
    grid-template-columns: 344px 156px 156px 344px !important;
  }
}

@media screen and (min-width:1024px) and (max-width:1359px) {
  .grid-container {
    grid-template-columns: 311px 140px 140px 311px !important;
  }

  .hover {
    padding: 0 24px 20px;
  }
}

@media screen and (min-width:1024px) and (max-width:1151px) {
  .grid-container {
    grid-template-columns: 290px 129px 129px 290px !important;
    gap: 20px 20px;
  }
}

@media only screen and (max-width: 1023px) {


  .grid-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: calc(50vw - 40px) calc(50vw - 40px);
    grid-template-rows: 250px 250px 250px 250px 250px;
    gap: 20px 20px;
    grid-template-areas:
      "oil health"
      "oil corp"
      "business business"
      "event constru"
      "rail constru";
  }

  .oil {
    grid-area: oil;
  }

  .health {
    grid-area: health;
  }

  .corp {
    grid-area: corp;
  }

  .business {
    grid-area: business;
  }

  .constru {
    grid-area: constru;
  }

  .event {
    grid-area: event;
  }

  .rail {
    grid-area: rail;
  }

  .hover{
    padding:20px;
  }

  .cardTitle{
    font-size:24px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .grid-container {

    flex-wrap: wrap;
    grid-auto-columns: unset;
    grid-auto-rows: unset;
    grid-template-columns: calc(100vw - 40px);
    grid-template-rows: 250px 250px 250px 250px 250px 250px 250px;
    gap: 20px 20px;
    margin: 40px auto;
    grid-template-areas:
      "oil"
      "health"
      "corp"
      "business"
      "event"
      "rail "
      "constru";
  }

  .grid-card {
    background-size: auto 100%;

  }

  .grid-card.oil,  .grid-card.constru {
    background-size: 100% auto;

  }
  .grid-card.rail {
    background-position: left center!important;
    
  }

}