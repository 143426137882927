.staff-container{
    width: 100%;
    height: 470px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    position: relative;
    
}
.staff-container::before{
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: #0C0C0C 0% 0% no-repeat padding-box;
    opacity: 0.8;
    z-index: 1;
}
.staff-info{
    z-index: 2;
    width: calc(calc(100% - 513px) + 32px)
}
.staff-image>img{
    width: 100%;
    height: 100%;
    
}
.staff-image{
    width: 513px;
    z-index: 2;
}


@media screen and (min-width:1024px) and (max-width:1151px) {
    .staff-info{

        width: calc(calc(100% - 470px) + 32px)
    }

    .staff-image{
        width: 470px;
    }

    .safetybox-title {
        padding-top: 30px !important;
        padding-bottom: 20px !important;
    }
    
}

@media (max-width:1023px) {
    .staff-image{
        width: 100%;
        height: auto;
        order: 0;
    }

    .staff-image img{
        height:300px
    }

    .staff-container{
        gap:16px !important;
        flex-direction: row;
    }
    .staff-info {
        width: 100% !important;
    }
    .staff-info .playfair{
        font-size: 42px;
    }
    .staff-info .inter{
        font-size: 16px;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .staff-container{
        padding: 50px 0px;
        height: 100%;
    }
    .staff-image img {
        height: 250px;
    }
    .staff-info .playfair{
        font-size: 34px;
        margin-bottom:10px
    }

    .staff-info .inter {
        padding:0;
        font-size:15px;
    }
}