#team {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-container {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 32px;
  max-width: 1276px;
  padding: 60px 0;
  align-items: center;
  justify-content: center;

  flex-direction: row-reverse;
}

.team-card {
  position: relative;
  max-width: 404px;
  max-height: 500px;
  width: calc((100% - 64px) / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.team-card-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-self: center;
  padding: 30px 30px 50px;
  gap: 10px;
}

@media screen and (min-width:1360px) and (max-width:1439px) {
  .team-card-name {
    font-size: 24px !important;
  }

  .team-card-position {
    font-size: 15px !important;
  }
}

@media screen and (min-width:1024px) and (max-width:1359px) {
  .team-card-name {
    font-size: 22px !important;
  }

  .team-card-position {
    font-size: 14px !important;
  }

  .team-card-info {
    padding: 30px 16px 50px;
  }
}

@media only screen and (max-width: 1023px) {
  .team-card {
    width: calc((100% - 40px) / 2);
  }
  .team-card-name {
    font-size: 22px !important;
  }

  .team-card-position {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .team-card {
    width: 100%;
  }
  .team-card img{
    width: 180px;
    height: 180px;
  }
  .team-container{
    justify-content: center;
    align-items: center;
  }
}
