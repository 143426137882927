@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

.inter {
  font-family: 'Inter';
}

.playfair {
  font-family: 'Playfair Display';
}

.abril {
  font-family: 'Abril Fatface', cursive;
}

img{
  border: none;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  top: calc(50% + 280px) !important;
  left: calc((100% - 1280px) / 2) !important;
  width: 46px !important;
}

.swiper-pagination-bullet {
  background-color: #dbefed !important;
  width: 15px !important;
  height: 15px !important;
  border: 3px solid #dbefed !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  border: 3px solid #70c2b4 !important;
  background-color: #70c2b4 !important;
}

.shadow {
  box-shadow: 0 0 10px rgb(0 0 0 / 35%);
}

.disabledArrow {
  background-color: #ddd !important;
  border: none !important;
}





.arrow-to-top {
  position: absolute;
  top: -20px;
  right: 20px;
}

.arrow-to-top-fixed {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
}

.arrow-top {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:0 0 10px rgba(0,0,0,0.3)
}

.mainContainer {
  width: 1280px;
}

.darkmoood{
  background-image: url("/public/images/shared/darkmode.png");
}

@media screen and (min-width:1360px) and (max-width:1439px) {
  .mainContainer {
    width: 1100px;
  }

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: calc((100% - 1100px) / 2) !important;
  }
}

@media screen and (min-width:1280px) and (max-width:1359px) {
  .mainContainer {
    width: 1001px;
  }

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: calc((100% - 1001px) / 2) !important;
  }
}


@media screen and (min-width:1024px) and (max-width:1279px) {
  .mainContainer {
    width: 1001px;
  }

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: calc((100% - 1001px) / 2) !important;
  }
}


@media screen and (min-width:1024px) and (max-width:1151px) {
  .mainContainer {
    width: 938px;
  }

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: calc((100% - 938px) / 2) !important;
  }
}

@media only screen and (max-width: 1023px) {
  .mainContainer {
    width: 100%;
    padding: 0 20px;
  }
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: 20px !important;
  }
}

@media (min-width:320px) and (max-width:767px) {
  .mainContainer {
    width: 100%;
    padding: 0 20px;
  }
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: 20px !important;
  }
  .mousey {
    width: 3px;
    padding: 8px 6px;
    height: 10px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }
}