.activeNav{
    color:#70C2B4 !important;
    border-bottom: 4px solid #70C2B4 !important ;
    
}
@media  screen and (min-width:1024px) and (max-width:1359px) {
    .nav-Container {
        display: flex;
        justify-content: center;
        gap: 64px;
        height: 100%;
    }

    .nav-img-container {
        width: 100%;
        justify-content: center;
    }

    .img-container {
        width: 100%;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .buttons-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .close-container {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .close-container svg {
        width:2rem;
        height:2rem
    }

    .nav-img {
        height: 100px;
    }
    .nav-main-container{
        border-radius: 0px !important;
    }
}
@media only screen and (max-width: 1023px) {
    .nav-Container {
        display: flex;
        justify-content: center;
        gap: 64px;
        height: 100%;
    }

    .nav-img-container {
        width: 100%;
        border-right: 1px solid #ddd;
        justify-content: center;
    }

    .img-container {
        width: 100%;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .buttons-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .close-container {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .close-container svg {
        width:2rem;
        height:2rem
    }

    .nav-img {
        height: 100px;
    }
    .nav-main-container{
        border-radius: 0px !important;
    }
    
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .nav-header{
        margin-right: 0px;
        margin-left: 0px;
        width: 100% ;
        top:0px;

    }
    .nav-header .relative {
        border-radius: 0px;
    }
    .nav-header-img{
        height: 50px;
    }
    .nav-Container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0px;
        height: 100%;
    }

    .nav-img-container {
        width: 100%;
        border-right: 0px;
        padding-bottom: 20px;
        justify-content: flex-start;
    }

    .img-container {
        width: auto;
        display: flex;
        height: 60px;
        justify-content: center;
        align-items: center;
    }

    .buttons-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: flex-start;
        margin-top: 2rem;
    }

    .close-container {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .nav-img {
        height: 100%;
    }
    .nav-main-container{
        border-radius: 0px !important;
    }
}