.safetygrid-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 0px;
}

.safetybox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.safetybox-image,
.safetybox-info {
    max-width: 638px;
    max-height: 500px;
    width: 50%;
    min-width: 300px;
}



@media screen and (min-width:1360px) and (max-width:1439px) {
    .safetybox-title {
        padding-top: 60px !important;
    }
}

@media screen and (min-width:1024px) and (max-width:1359px) {
    .safetybox-title {
        padding-top: 40px !important;
        font-size: 50px;
    }
}

@media only screen and (max-width: 1023px) {
    .safetybox-title {
        padding-top: 20px !important;
        font-size: 36px;
        padding-bottom: 10px !important;
    }

    .safetybox-des {
        font-size: 16px;
        padding-bottom: 0px;
    }

    .safetybox-image {
        overflow: hidden;
    }

    .safetybox-image img {
        max-width: unset;
        height: 330px;
        width: 100%;
    }
}



@media (min-width:320px) and (max-width:767px) {
    .safetybox-image {
        min-width: unset;
        width: 100%;
    }

    .safetybox-info {
        max-height: unset;
        width: 100%;
        padding: 0;
    }

    .safetybox-title {
        font-size: 34px;
    }

    .safetybox-container {
        margin-bottom: 50px;
    }

    .safetybox-image img {
        height: 250px;
        border-radius: 10px;
    }
    .safetybox-des {
        font-size: 15px;
    }
    .safetygrid-container {
        padding: 70px 0px 0px 0px;
    }
}