.galleryModel-swiper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.galleryModel-card {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.galleryModel-card>img {
  padding: 1px;
}

.galleryModel-carousel {
  width: 90%;
}

.galleryModel-carousel>.carousel-initialized,
.galleryModel-carousel>.carousel-initialized>.carousel-track,
.galleryModel-carousel>.carousel-initialized>.carousel-track>.carousel-item,
.galleryModel-carousel>.carousel-initialized>.carousel-track>.carousel-item>.carousel-row {
  height: 100% !important;
}

.galleryModel-prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.galleryModel-next {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .galleryModel-carousel {
    width: 100%;
  }
}