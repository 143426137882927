.Whoweare {
  display: flex;
  justify-content: center;
  align-items: center;
}
.whoWeAreContainer {
  display: flex;
  height: 376px;
  margin: 70px 0;
  justify-content: space-between;
  align-items: center;
  gap:32px;
}
.left {
  width: calc((100% - 32px) / 2);
}

.head {
  display: flex;
  align-items: center;
  gap: 16px;
}
.line {
  width: 120px;
  height: 4px;
  background-color: #70c2b4;
  border: 1px solid #70c2b4;
  border-radius: 2px;
}
.right > img {
  width: 100%;
  max-width: 604px;
  height: auto;
  max-height: 374px;
  object-fit: cover;
  
}
.right{
  width: calc((100% - 32px) / 2);
}

@media only screen and (max-width: 1023px) {
  .right > img {
    height: 364px;
  }
  .left-title{
    font-size: 42px;
    margin-bottom: 10px;
  }
  .left > p {
    font-size: 16px;
  }
  .head> .line {
    width: 80px;
  }
  .head > .inter{
    font-size: 13px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {

    .whoWeAreContainer{
        padding: 0px;
        gap: 16px !important;
        flex-direction: column;
        height: auto;
        margin: 50px 0;
        
    }
    .whoWeAreContainer .left,
    .whoWeAreContainer .right{
      width: 100%;
    }
    .left-title{
        font-size: 34px;
        padding: 0 0px 10px 0px;
    }

    .right > img {
      height: 250px;
  }
  .left > p {
    font-size: 15px;
  }
}
