@media screen and (min-width: 320px) and (max-width: 767px) {
    .footer-container {
        flex-direction: row;
        align-items: center;
    }

    .footer-img-container {
        width: calc(50% - 16px);
        height: 140px;
    }

    .footer-site-map {
        width: calc(50% - 16px);
    }
    .footer-site-map p{
        text-align: left;
    }

    .footer-contact-us {
        align-items: flex-start;
    }

    .arrow-top{
        width:50px;
        height:50px;
        right:20px;
        bottom:20px;
    }
}