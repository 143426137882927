
@media screen and (min-width: 320px) and (max-width: 767px) {
    .gallery-page ul{
        gap:16px;
    }
    
    .gallery-page li {
        width: calc((100% - 16px) / 2);
    }
    .gallery-page li a {
        width: 100%;
        padding: 0.5rem 0;
        font-size: 16px;
    }
}