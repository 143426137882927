#catering {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .catering-swiper>.slider-carousel>.carousel-initialized>.carousel-track>.carousel-item {
  display: flex;
  justify-content: center;
} */

.catering-swiper {
  opacity: 1;
  margin: 62px auto;
  width: 100%;
  height: auto;
  position: relative;
}

.catering-card {
  min-height: 500px;
  background-size: cover;
  /* max-width: 404px; */
  width: 404px;
  /* min-width: 404px; */
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  border-radius: 10px;
  margin: auto;
  overflow: hidden;
}


.catering-card:hover .hover .catering-description{
  opacity: 1;
  max-height: 500px;
}
.catering-card::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent linear-gradient(180deg, #12121200 70%, #121212 100%) 0% 0% no-repeat padding-box;
  z-index: 0;
}

.catering-card:hover::before {
  content: '';
  background-color: #010101;
  opacity: 58%;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 6px;
  animation: moveShadowin 0.5s ease-in;
  animation-fill-mode: forwards;
}

.catering-card:hover>.catering-title {
  transform: translateY(-200%);
  transition: transform 0.5s ease, opacity 0.5s ease;
  /* Add a transition for smooth animation */
}

.catering-card:not(:hover)>.catering-title {
  transform: translateY(0);
  transition: transform 0.5s ease, opacity 0.5s ease;
  /* Add a transition for smooth animation */
}

.catering-card:hover>.catering-button {
  display: block;
  bottom: 30px;
  animation: moveTitlein 0.5s ease-in;
  animation-fill-mode: forwards;
}

.catering-card:hover>.catering-description {
  opacity: 1;
  transform: translateY(-50%);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.catering-card:not(:hover)>.catering-button {
  display: block;
  bottom: 30px;
  animation: moveDesout 0.5s ease-in;
  animation-fill-mode: forwards;
}

.catering-card:not(:hover)>.catering-description {
  opacity: 0;
  transform: translateY(100%);
  /* Move it back down to hide it */
  transition: transform 0.5s ease, opacity 0.5s ease;
  /* Add a transition for smooth animation */
}

.catering-card:not(:hover)::before {
  content: '';
  background-color: #010101;
  opacity: 58%;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 6px;
  animation: moveShadowout 0.5s ease-out;
  animation-fill-mode: forwards;
}

.catering-swiper>.slider-carousel {
  height: 500px;
}

.catering-title{
  font-size: 28px;
  color: #ffffff;
  bottom: 0;
  transition: all 0.5s ease-in-out;
}
.catering-description {
  font-size: 17px;
  color: #ffffff;
  opacity: 0;
  bottom: 0;
  max-width: 404px;
  max-height: 0;
  transition: all 0.5s ease-in-out;
}

.catering-button {
  position: relative;
  width: 150px;
  height: 50px;
  color: white;
  background-color: #70c2b4;
  border-radius: 30px;
  font-family: inter;
  z-index: 1;
  margin-top: 30px;
}


/* .catering-title {
  padding-top: 313px;
} */
.catering-prev {
  position: absolute;
  left: -1%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.catering-next {
  position: absolute;
  z-index: 5;
  right: -1%;
  top: 50%;
  transform: translateY(-50%);
}


.carousel-arrow {
  background-color: #70c2b4;
  height: 50px;
  width: 50px;
  border: 1px solid #70c2b4;
  border-radius: 30px;
}








@media screen and (min-width:1360px) and (max-width:1439px) {
  .catering-card {
    width: 344px;
    margin: auto;
    min-width: unset;
  }
}


@media screen and (min-width:1024px) and (max-width:1359px) {
  .services-desc {
    margin-right: 10% !important;
    margin-left: 10% !important;
  }

  .catering-card {
    width: 312px;
  }
}

@media screen and (min-width:1024px) and (max-width:1151px) {
  .catering-card {
    width: 292px;
  }
}

@media only screen and (max-width: 1023px) {
  .main-title-back{
    font-size: 84px;
  }
  .main-title-front{
    font-size: 42px;
    margin-top: -98px;
  }
  .services-desc{
    font-size: 16px;
    margin-bottom: 0px ;
  }
  .catering-card {
    width: calc((100% - 20px))
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-title-back{
    font-size: 56px;
  }
  .main-title-front {
    font-size: 30px;
    margin-top: -67px;
}
  .services-desc{
    font-size: 15px;
    margin-bottom: 0px ;
  }
  .catering-card {
    width: 100%
  }
  .catering-swiper{
    margin: 40px auto;
  }
  .catering-button{
    font-size: 14px;
    width: 140px;
    height: 44px;
  }
}