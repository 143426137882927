/* GalleryImageSwiper.css */


@media only screen and (min-width: 768px) {



    .SwiperGallery {
        height: fit-content;
        position: relative;
    }

    .SwiperGallery>.swiper-initialized,
    .SwiperGallery {
        min-height: 750px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide>.swiper-slide-content {
        width: 100%;
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out 0.5s;
        min-height: 300px;
        max-height: 300px;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide>.swiper-slide-content>img {
        object-fit: cover;
        min-height: 300px;
        border-radius: 10px;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper {
        min-height: 500px;
        display: flex;
        align-items: center;
    }

    /* Scale down the previous slide */
    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide.swiper-slide-prev {
        transform: scale(1.5);
        z-index: 10;
        opacity: 0.5;
        filter: blur(0.8px);
        overflow: hidden;
        /* Apply brightness filter for previous slide */
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;
    }

    /* Scale up the active slide */
    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide.swiper-slide-active {
        transform: scale(2);
        z-index: 20;
        opacity: 1;
        overflow: hidden;
        /* Reset brightness filter for active slide */
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;
    }

    /* Scale up the next slide */
    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide.swiper-slide-next {
        transform: scale(1.5);
        opacity: 0.5;
        filter: blur(0.8px);
        overflow: hidden;
        /* Apply brightness filter for next slide */
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;
        z-index: 10;
    }




    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next):not(.swiper-slide-prev) {
        opacity: 0.2;
        overflow: hidden;
        filter: blur(2px);
        /* Apply brightness filter for next slide */
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;

    }

    /* Adjust image size for previous slide */

    .SwiperGallery>.swiper-initialized>.swiper-pagination {
        display: none;
    }

    .swiperArrow {
        position: absolute;
        z-index: 50;
        background-color: #70c2b4;
        height: 50px;
        width: 50px;
        border: 1px solid #70c2b4;
        border-radius: 30px;
    }

    .prevSwiper {
        top: 50%;
        transform: translateY(-50%);
        left: -25px;
    }

    .nextSwiper {
        transform: translateY(-50%);
        top: 50%;
        right: -25px;
    }

}




@media screen and (min-width:1024px) and (max-width:1359px) {

    .SwiperGallery>.swiper-initialized,
    .SwiperGallery {
        min-height: 652px;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide>.swiper-slide-content {
        width: 100%;
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out 0.5s;
        min-height: 270px;
        max-height: 270px;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide>.swiper-slide-content>img {
        object-fit: cover;
        min-height: 270px;
        border-radius: 10px;
    }

}




@media (min-width:320px) and (max-width:767px) {
    .SwiperGallery {
        height: fit-content;
        width: 100%;
        padding: 40px 0 ;

        position: relative;
    }

    .SwiperGallery>.swiper-initialized,
    .SwiperGallery {
        min-height: 500px;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide>.swiper-slide-content {
        width: 100%;
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out 0.5s;
        min-height: 450px;
        max-height: 450px;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide>.swiper-slide-content>img {
        object-fit: cover;
        min-height: 450px;
        border-radius: 10px;
    }

    .SwiperGallery>.swiper-initialized>.swiper-wrapper {
        min-height: 500px;
        display: flex;
    }

    /* Scale down the previous slide */
    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide.swiper-slide-prev {

        z-index: 10;
        opacity: 0.5;
        filter: blur(0.8px);
        overflow: hidden;
        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;
    }

    /* Scale up the active slide */
    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide.swiper-slide-active {
        z-index: 20;
        opacity: 1;
        overflow: hidden;

        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;
    }

    /* Scale up the next slide */
    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide.swiper-slide-next {
        opacity: 0.5;
        filter: blur(0.8px);
        overflow: hidden;

        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;
        z-index: 10;
    }




    .SwiperGallery>.swiper-initialized>.swiper-wrapper>.swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next):not(.swiper-slide-prev) {
        opacity: 0.2;
        overflow: hidden;
        filter: blur(2px);

        transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out, filter 0.5s ease-in-out;

    }

    /* Adjust image size for previous slide */

    .SwiperGallery>.swiper-initialized>.swiper-pagination {
        display: none;
    }

    .swiperArrow {
        position: absolute;
        z-index: 50;
        background-color: #70c2b4;
        height: 50px;
        width: 50px;
        border: 1px solid #70c2b4;
        border-radius: 30px;
    }

    .prevSwiper {
        top: 50%;
        transform: translateY(-50%);
        left: 0
    }

    .nextSwiper {
        transform: translateY(-50%);
        top: 50%;
        right: 0;
    }
}

