/* .gallerySlider-swiper>.slider-carousel>.carousel-initialized>.carousel-track>.carousel-item {
  display: flex;
  justify-content: center;
} */

.gallerySlider-swiper {
  opacity: 1;
  margin: 62px auto;
  width: 100%;
  max-width: 1280px;
  height: auto;
  position: relative;
}

.gallerySlider-card {
  min-height: 500px;
  max-height: 500px;
  background-size: cover;
  max-width: 404px;
  width: 404px;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
}

.gallerySlider-card > img{
  max-width:unset;
  height:100%
}

.gallerySlider-card::before {
  content: '';
  opacity: 58%;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 6px;
}

.gallerySlider-swiper>.slider-carousel {
  /* height: 500px; */
  width: auto;
  z-index: 3;
}





.gallerySlider-title,
.gallerySlider-description {
  padding: 0px 30px;
  position: relative;
}

.gallerySlider-title {
  padding-top: 313px;
}

.gallerySlider-prev {
  position: absolute;
  left: -1%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.gallerySlider-next {
  position: absolute;
  z-index: 5;
  right: -1%;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-arrow {
  background-color: #70c2b4;
  height: 50px;
  width: 50px;
  border: 1px solid #70c2b4;
  border-radius: 30px;
}

.gallery-btn-box {
  width: 100px;
  height: 100px;
  background: #70c2b4 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #70c2b4;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 767px) and (min-width: 320px) {
  .gallerySlider-swiper {
    width: auto;
    padding: 0px 10px;
  }

  .gallerySlider-swiper>.slider-carousel>.carousel-initialized>.carousel-track>.carousel-item {
    
    width: auto;
  }

  .gallerySlider-card {
    width: auto;
    margin: auto;
  }

  .gallerySlider-prev {
    left: 0.5%;
  }
}



@media screen and (min-width:1360px) and (max-width:1439px) {
  .gallerySlider-card{
    max-width: 344px !important;
    
  }
}


@media screen and (min-width:1024px) and (max-width:1359px) {
  .gallerySlider-card{
    width: 312px !important;
  }
}

@media screen and (min-width:1024px) and (max-width:1151px) {
  .gallerySlider-card{
    width: 292px !important;
  }
}

@media only screen and (max-width: 1023px) {
  
  .gallerySlider-card{
    width: 100% !important;
    overflow: hidden;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .gallerySlider-card{
    width: 100%  !important;
    overflow: hidden;
  }
  .gallerySlider-swiper{
    padding: 0;
    margin: 40px auto;
  }
}
