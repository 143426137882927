.Erorr {
    position: relative;
  height: 100vh;
  background-size: cover;
  width: auto;
  background-position: center center;
  position: relative;
  display: flex;
  justify-content: center;
}
.Erorr::before{
    position: absolute;
    content: '';
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: #0C0C0C 0% 0% no-repeat padding-box;
    opacity: 0.5;
    z-index: 1;
}
.Erorr-title{
  color: #70C2B4;
  font-family: 'Abril Fatface', cursive;

}
.Error-container{
  width: 450px;
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}