.timelineConatiner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}

.timelineStyles {
  width: 100%;
  min-height: 480px;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.arrowButton {
  background-color: #70c2b4;
  height: 50px;
  width: 50px;
  border: 1px solid #70c2b4;
  border-radius: 30px;
}

.arrowLeft {
  position: absolute;
  z-index: 5;
  left: 0;
}

.arrowRight {
  position: absolute;
  z-index: 5;
  right: 0;
}

.prevArrow,
.nextArrow {
  margin: 50% auto;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  fill: white;
}

.timelineItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  margin-top: -6.9%;
  position: relative;
}

.timelineDate {
  display: flex !important;
  flex-direction: column;
  width: 100% !important;
  justify-content: space-around;
  align-items: center;
}

.timelineUp {
  display: flex;
  width: 79%;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-end;
  margin-bottom: -10%;
}

.timelineDown {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25px;
  position: relative;
}

.dotContainer {
  width: 92%;
  /* padding: 190px; */
  display: flex;
  justify-content: center;
}

.dotLines {
  width: 100%;
  height: 13px;
  position: relative;
  display: flex;
}

.dotLines::after,
.dotLines::before {
  content: '';
  position: absolute;
  margin: auto;
  height: 2px;
  background: #70c2b4;
  width: 45%;
  top: 45%;
}

.dotLines::after {
  left: 0px;
}

.dotLines::before {
  right: 0px;
}
.disabledArrowDark{
  background-color: #252525;
  border-color: #252525;
}
.disabledArrowDark img{
  opacity: 0.1;
}
.dot {
  height: 8px;
  width: 8px;
  border: 2px solid #70c2b4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.dotActive {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  background-color: #70c2b4;
}

.timelineMonth {
  font-size: 15px;
  font-weight: bold;
  color: #121212;
  opacity: 0.16;
  text-align: center;
}

.timelineYear {
  font-size: 42px;
  font-weight: normal;
  opacity: 0.16;
  text-align: center;
}

.carousel-item.active>.carousel-row>.timelineDate>.yearActive {
  font-size: 72px !important;
  opacity: 1 !important;
}

.carousel-item.active>.carousel-row>.timelineDate>.monthActive {
  opacity: 1 !important;
  margin-top: -15%;
}

:after {
  left: 100%;
}

:before {
  right: 100%;
}

@media (max-width: 1281px) {
  .timelineConatiner {
    height: 100% !important;
  }

  .dotContainer {
    padding: 40px !important;
  }

  .timelineDown>.arrowRight {
    right: 15px;
  }

  .timelineDown>.arrowLeft {
    left: 15px;
  }

  .carousel-item.active>.carousel-row>.timelineDate>.yearActive {}
}

@media (max-width: 767px) and (min-width: 320px) {
  .carousel-item.active>.carousel-row>.timelineDate>.yearActive {
    font-size: 32px !important;
    opacity: 1 !important;
  }

  .carousel-item.active>.carousel-row>.timelineDate>.monthActive {
    opacity: 1 !important;
    margin-top: 0;
  }

  .dotContainer {
    padding: 5% !important;
  }

  .arrowLeft {
    left: 0;
  }

  .arrowRight {
    right: 0;
  }

  .timelineItems {
    overflow: hidden;
  }

  .timelineYear {
    font-size: 24px !important;
    opacity: 0 !important;
  }

  .yearActive {
    font-size: 36px !important;
  }

  .timelineMonth {
    font-size: 10px !important;
    opacity: 0 !important;
  }

  .monthActive {
    font-size: 13px !important;
  }

  .timelineUp {
    gap: 1.5rem !important;
  }
}



@media only screen and (max-width: 1023px) {
  .timelineStyles {
    min-height: 440px;
  }

  .timeline-header {
    font-size: 42px;
    padding-bottom: 0px !important;
  }

  .description>.inter {
    font-size: 16px;
  }

  .timelineDown>.arrowLeft {
    left: 0;
  }

  .timelineDown>.arrowRight {
    right: 0;
  }

  .dotContainer {
    width: 100%;
  }

  .description {
    padding: 3rem 32px;
  }

  .timelineConatiner {
    padding: unset;
  }


  .timelineYear {
    font-size: 30px;
  }

  .carousel-item.active>.carousel-row>.timelineDate>.yearActive {
    font-size: 52px !important;
  }


}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .timeline-header {
    font-size: 34px;
    padding-bottom: 0px !important;
    padding: 50px 0;
  }

  .carousel-item.active>.carousel-row>.timelineDate>.yearActive {
    font-size: 42px !important;
  }

  .timelineStyles {
    min-height: 520px;
  }
  .description {
    padding: 3rem 0;
  }
  .description>.inter {
    font-size: 15px;
  }
}