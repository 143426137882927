.practice-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
}

.practice-info {
  width: calc((100% - 64px) / 3);
  max-height: 776px;
  padding: 47px 40px;
  border-radius: 10px;
}

.bullet-container {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.practices-bullet {
  display: flex;
  gap: 20px;
}

.practices-bullet>img {
  width: 22px;
  height: 22px;
}

.practice-photos {
  width: calc((100% - 64px) / 3);
  display: flex;
  flex-direction: column;
  max-height: 776px;
  gap: 32px;
  border-radius: 10px;
}

.practice-video {
  width: calc((100% - 64px) / 3);
  max-height: 776px;
  position: relative;
  border-radius: 10px;
}

.video-play-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.btn-box {
  width: 132px;
  height: 132px;
  background: #70c2b4 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #70c2b4;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:320px) {

  .practice-info {
    height: auto;
    max-height: fit-content;
  }

  .practice-video {
    max-height: 404px;
    width: 100%;
  }

  .practice-video-bg {
    height: 404px !important;
    width: 100%;
  }
}







@media screen and (min-width:1360px) and (max-width:1439px) {

  .practice-info,
  .practice-photos,
  .practice-video {
    max-height: 832px;
  }

  .practice-photo {
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
  }

  .practice-photo>img {
    max-width: unset;
  }

  .practice-video {
    overflow: hidden;
    border-radius: 10px;
  }

  .practice-video>img {
    max-width: unset;
    height: 100%;
  }

}

@media screen and (min-width:1024px) and (max-width:1359px) {
  .hse-practice {
    flex-wrap: wrap;
  }

  .practice-info {
    max-height: unset;
    width: 100%;
    max-width: unset;
  }

  .practice-photos {
    flex-direction: row;
    flex-wrap: wrap;
    width: calc((((100% - 64px) / 3) * 2) + 32px)
  }

  .practice-photo {
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
    width: calc(50% - 16px);
    height: 170px;
  }

  .practice-photo>img {
    max-width: unset;
    height: 100%;
  }

  .practice-video {
    overflow: hidden;
    border-radius: 10px;
    width: calc((100% - 64px) /3);
    max-height: 372px;
  }

  .practice-video>img {
    max-width: unset;
    height: auto;
    width: 100%;
  }
}

@media screen and (min-width:1024px) and (max-width:1151px) {
  .hse-practice {
    gap: 20px !important;
  }

  .practice-photos {
    width: calc((((100% - 40px) / 3) * 2) + 20px);
    gap: 20px !important;
  }

  .practice-photo {
    width: calc(50% - 10px);
  }

  .practice-video {

    width: calc((100% - 40px) /3);

  }
}

@media only screen and (max-width: 1023px) {
  .hse-practice {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .practice-info {
    max-height: unset;
    width: 100%;
    max-width: unset;
  }

  .practice-photo>img {
    max-width: unset;
    height: 100%;
  }


  .practice-video>img {
    max-width: unset;
    height: auto;
    width: 100%;
  }

  .hse-practice {
    gap: 20px !important;
  }

  .practice-photos {
    flex-direction: row;
    flex-wrap: wrap;
    width: calc((((100% - 40px) / 3) * 2) + 20px);
    gap: 20px !important;
  }

  .practice-photo {
    width: calc(50% - 10px);
    height: 170px;
    overflow: hidden;
    border-radius: 10px;
  }

  .practice-video {
    overflow: hidden;
    border-radius: 10px;
    max-height: 372px;
    width: calc((100% - 40px) /3);

  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .practice-container {
    padding: 50px 0;
  }

  .bottom .inter {
    font-size: 15px;
  }

  .practice-info {
    padding: 30px 20px;
  }

  .practice-photos {
    width: 100%;
    flex-direction: column;
  }

  .practice-photo {
    width: 100%;
  }

  .practice-video {
    width: 100%;

  }

  .btn-box {
    width: 100px;
    height: 100px;
  }

  .btn-box img{
    width:30px;
  }
}