.menuContainer {
  width: 1233px;
  height: 750px;
  border-radius: 14px;
}

.menuTitle {
  position: relative;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 66px;
  margin-top: 36px;
  height: 66px;
}

.menuTitle::after {
  content: "";
  position: absolute;
  top: 65px;
  right: 0;
  left: 0;
  width: 424px;
  height: 1px;
  background: rgba(111, 192, 185, 0.84);
  margin: 0 auto;
}

.menuSubtitle {
  position: relative;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 47px;
  margin-top: 14px;
  padding-right: 20px;
}

.menuSubtitle::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 29px;
  height: 29px;
  right: 0px;
  top: 13px;
  border: 1px solid #6FC0B9;
  border-radius: 50%;
}

.subTitleNo {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  color: #6FC0B9;
  margin-top: 29px;
  margin-left: 10px;
}

.menuPoster {
  position: absolute;
  bottom: 0px;
}

.menuItem {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
  text-align: left;
}

.menuItemDes {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

.desContainer {
  max-width: 253px;
}

.menuSwiper {
  width: 100%;
  height: 100%;
}

.swiperBox {
  height: 648px !important;
}

.menuSwiper>.carousel-dots {
  position: absolute;
  left: 10px;
  /* margin-top: 50px; */
  z-index: 100;
  bottom: -20px;
}


.carousel-dots>li>button::before {
  color: #6FC0B9 !important;
  font-size: 60px;
  margin-top: -60px !important;
}

.carousel-dots>li.carousel-dots-active>button::before {
  opacity: 1;
}

.menuList {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-top: 50px;
  width: 70%;
}

.menuItemContainer {
  display: flex;
  width: 420px;
}

.menuContainer>.catering-prev {
  left: -2.5% !important;
}

.menuContainer>.catering-next {
  right: -2.5% !important;
}


@media screen and (min-width:1024px) and (max-width:1359px) {
  .menuContainer {
    width: 1001px;
    height: 100vh;
  }

  .menuPoster {
    width: 300px;
    bottom: 0px;
  }

  .menuItemContainer {
    width: 50%;
    min-height: 70px;
  }

  .menuList {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-top: 50px;
    width: 70%;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
  }

  .desContainer {
    padding-right: 10px;
  }

  .carousel-dots {
    display: none !important;
  }

  .menu-model-image {
    height: 50% !important;

  }

  .swiperBox {
    height: calc(100vh - 102px) !important;
  }
}

@media screen and (min-width:1024px) and (max-width:1151px) {
  .menuContainer {
    width: calc(100% - 64px);
  }
}

@media only screen and (max-width: 1023px) {
  .menuContainer {
    width: calc(100% - 40px);
    height: 100vh;
  }

  .menuPoster {
    width: 230px;
    bottom: 0px;
  }

  .menuItemContainer {
    width: 50%;
    min-height: 70px;
  }


  .menuItemContainer img{
    width:26px;
    margin-top:2px;
  }

  .menuList {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-top: 50px;
    width: 70%;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
  }

  .desContainer {
    padding-right: 10px;
  }

  .carousel-dots {
    display: none !important;
  }

  .menu-model-image {
    height: calc(100% - 70px) !important;
    overflow: auto;
  }

  .swiperBox {
    height: calc(100vh - 102px) !important;
  }
  .menuTitle{
    font-size: 42px;
  }
  .menuSubtitle{
    font-size: 32px;
  }
  .swiper-modal-subtitle{
    height: 70px;
  }
  .menuItem{
    font-size: 18px;
  }
  .menuItemDes{
    font-size: 14px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .menuContainer {
    width: 100%
  }
  .menuTitle {
    font-size: 24px;
    margin-top: 50px;
  }
  .close-menu{
    width: 24px;
    right: 20px;
    top: 20px;
  }
  .menuList {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 20px;
    width: 100%;
  }
  .swiperBox {

    height: calc(100vh - 156px)!important
    
    }

  .menuTitle::after {
    width: 80%;
  }	
  .menuItemContainer	{
    margin-bottom: 30px;
    width: 100%;
    min-height: unset;
  }
  .menuContainer{
    height: calc(100vh - 40px)
  }
  .menuPoster {
    width: 150px;
  }
  .menu-model-image {
    height: calc(100% - 220px) !important;
}
}