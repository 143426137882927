.HeroContainer {
  height: 650px;
  background-size: cover;
  width: auto;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.HeroContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: auto auto;
  height: 90%;
  padding-bottom: 3%;
}
.Blue {
  width: 30px;
  height: 4px;
  border: 1px solid #70c2b4;
  border-radius: 2px;
  background-color: #70c2b4;
}
.Slogan {
  max-width: 668px;
  text-align: center;
}
.RouteHome {
  display: flex;
  gap: 10px;
  /* width: 214px; */
  height: 52px;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  border-radius: 6px;
}

.mouse {
  width: 3px;
  padding: 10px 10px;
  height: 15px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}

.scrolller {
  width: 3px;
  height: 5px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.scrolll-down-container {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
  position: absolute;
  bottom: 2%;
}




@media (min-width:1024px) and (max-width:1439px) {
  .HeroContainer{
    height: 450px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .HeroContainer{
    height: 450px !important;
  }
}
@media (max-width: 767px) and (min-width: 320px) {
  .HeroContent{
    padding: 8% 10px 0px 10px;
    height: 80%;
  }
  .HeroContent .playfair {
    font-size: 42px;
  }
  .RouteHome{
    padding: 12px 24px;
  }
}