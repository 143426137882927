#getintouch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.getintouchContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  /* align-items: center; */
  /* gap: 154px; */
  padding: 100px 0;
}

.getintouchInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 56px;
}

.Info-Head {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 4px;
}

.Info-Head>img {
  width: 20px;
  height: 20px;
}

.Info-body {
  margin-left: 34px;
}

.Info-body>p {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: normal;
}

.socialLink {
  background-color: #70c2b4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialLink>img {
  width: 16px;
  height: 16px;
}

.sendus-form {
  box-shadow: 0px 10px 25px #0000001a;
  border-radius: 10px;
  opacity: 1;
  width: calc((100% - 32px) / 2);
  padding: 50px;
}

.form-input {
  border-radius: 6px;
  opacity: 1;
  width: 100%;
  height: 60px;
  text-indent: 20px;
}

.form-input:focus,
.form-textarea:focus {
  outline: none !important;
}

.form-textarea {
  border-radius: 6px;
  opacity: 1;
  width: 100%;
  text-indent: 20px;
  resize: none;
  padding-top: 16px
}

.map-iframe {
  position: relative;
  /* margin-top: -245px; */
}

.left-getintouch {
  width: calc((100% - 32px) / 2);
}

@media screen and (min-width:1024px) and (max-width:1439px) {
  /* .map-iframe {
    margin-top: -180px !important;
  } */
}

@media only screen and (max-width: 1023px) {
  .getintouchContainer {
    flex-wrap: wrap;
    padding: 100px 0 50px;
  }

  .left-getintouch,
  .sendus-form {
    width: 100%;
  }

  .left-getintouch {
    margin-bottom: 50px
  }

  .Info-Head p {
    font-size: 16px
  }

  .Info-body>p {
    font-size: 15px
  }

  .sendus-form {
    padding: 32px
  }

  .sendus-form p {
    font-size: 16px
  }

  .map-iframe {
    margin-top: 0;
    margin-bottom: 50px;
    border-radius: 10px;
  }

}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .getintouchContainer {
    flex-wrap: wrap;
    padding: 50px 0 50px;
  }

  .getintouchInfo {
    margin-top: 30px
  }

  .sendus-form {
    padding: 20px;
  }

  .form-input {
    font-size: 15px
  }

}