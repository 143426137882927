#facility {
  display: flex;
  justify-content: center;
  align-items: center;
}

.facility-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 60px 0%;
  gap: 20px;
}

.facility-card {
  height: 270px;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
}
.middleCard{
  height: 560px !important;
}
.facility-shadow {
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: transparent linear-gradient(180deg, #12121200 70%, #121212 100%) 0% 0% no-repeat padding-box;
}

.gap-card {

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc((100% - 40px) / 3);
}

@media screen and (min-width:1024px) and (max-width:1359px) {
  .facility-shadow{
    padding: 24px;
  }
}

@media (max-width: 1023px) {
  .facility-container{
    flex-direction: column;
  }
  .gap-card {
    flex-direction: row;
    width: 100% ;
  }
  .middleCard{
    width: 100%;
    height: 270px !important;
  }
}
@media only screen and (max-width: 1023px) {
  .facility-shadow{
    padding: 20px;
  }
  .facility-title{
    font-size: 24px;
  }
}
@media (min-width:320px) and (max-width:767px) {
  .gap-card {
    flex-direction: column;
    width: 100% ;
  }
  .facility-container {
    padding: 40px 0;
  }
}
