.swiper-container {
  width: 100%;
  /* overflow: hidden; */
  touch-action: pan-y;
  height: 100%;
}

.swiper-container>.swiper-list>.slider-carousel>.carousel-initialized>.carousel-track {
  height: 172px !important;
  overflow: hidden;
}

.swiper-list {
  /* display: flex; */
  /* flex-direction: row; */
  /* list-style: none; */
  min-width: 100%;

  /* margin: 0; */
  /* transition: transform 0.3s ease-out; */

}

.swiper-container>.swiper-list>.slider-carousel>.carousel-initialized>.carousel-track>.carousel-item>.carousel-row {
  padding: 95px 0px 20px 0px;
  margin-bottom: 0px;
}

.swiper-list.swiping {
  transition: none;
}

.swiper-indicator {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 15px 0 0 0;
  padding: 0;
}

.swiper-indicator-item {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: rgba(92, 88, 88, 0.5);
  cursor: pointer;
}

.swiper-indicator-item.active-item {
  background-color: black;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .swiper-container>.swiper-list>.slider-carousel>.carousel-initialized>.carousel-track>.carousel-item>.carousel-row {
    padding: 50px 0px 20px 0px;
    margin-bottom: 0px;
  }

  .swiper-container>.swiper-list>.slider-carousel>.carousel-initialized>.carousel-track {
    height: 140px !important;
    overflow: hidden;
  }

 
}