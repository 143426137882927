.testimotial {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimotialContainer {
  display: flex;
  height: 752px;
  margin: 70px auto;
  justify-content: center;
  gap: 32px;
}

.bullet {
  display: flex;
  align-items: flex-start;
  align-items: center;
  gap: 23px;
  width: 578px;
  height: 60px;
  margin-bottom: 15px;
}

.bulletIcon {
  width: 60px;
  height: 60px;
  background-color: #70c2b4;
  border: 1px solid #70c2b4;
  border-radius: 6px;
}

.testimontail-img {
  width: 662px;
  height: 560px;
  object-fit: cover;
  border-radius: 12px;
}

@media only screen and (max-width: 1023px) {
  .testimotialContainer {
    flex-direction: column;
    height: auto;
  }

  .testimotial-text {
    font-size: 42px;
    margin-bottom: 10px;
    width: 100%;
  }

  .left-testimotial .inter {
    font-size: 16px;
    max-width: unset;
  }

  .bullet {
    gap: 20px;
    height: 50px;
    margin-bottom: 20px;
  }

  .bulletIcon {
    width: 50px;
    height: 50px;
  }

  .bulletIcon img {
    width: 30px;
    height: 30px;
    margin: 10px
  }

  .right-testimotial {
    margin-top: 0;
  }

  .right-testimotial .playfair {
    font-size: 18px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .bullet {
    width: auto;
  }
  .testimotialContainer{
    margin: 50px auto;
    gap:0px
  }
  .testimotial-text{
    font-size: 34px;
  }

  .testimontail-img{
    height:auto;
  }
  .left-testimotial .inter {
    font-size: 15px;
  }
}