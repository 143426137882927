.homeslider-container {
  height: 100vh;
  background-size: cover;
  width: auto;

  background-repeat: no-repeat;
  background-position: center center;
}

.swiper-Container {
  position: relative;
  max-width: 1920px;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  margin: auto auto;
}

.chefpostion {
  max-width: 514px;
  width: 30%;
  position: absolute;
  right: 10%;
  bottom: -10%;
}

.platepostion {
  max-width: 514px;
  width: 30%;
  position: absolute;
  right: 10%;
  bottom: 0px;
}

.slide-typing-container {
  margin: auto 0px;
  width: 70%;
  z-index: 5;
}

.mousey {
  width: 3px;
  padding: 10px 10px;
  height: 15px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}

.scroller {
  width: 3px;
  height: 5px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.scrollDownContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2%;
  z-index: 49;
}

/* w-full flex justify-center absolute lg:bottom-[20px] bottom-[-80px] */


.volume-button {
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  top: 120px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width:1024px) and (max-width:1279px) {
  .welcome-home {
    font-size: 58px;
  }
  .welcome-desc{
    
    font-size: 34px;
  }
}

@media only screen and (max-width: 1023px) {
  .welcome-home {
    font-size:48px;
  }
  .welcome-desc{
    
    font-size: 28px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .welcome-home {
    font-size:28px;
  }
  .welcome-desc{
    font-size: 16px;
  }
  .volume-button {
    top: 100px;
    right: 20px;
  }
  
}